import axios from "axios";
import { Envs } from "../conf/envs";

const token = localStorage.getItem(Envs.TOKEN_NAME);

axios.defaults.headers.common = {
    Authorization: `Bearer ${token?.substring(1, token.length - 1)}`,
};
const API_URL_AUTH = `${Envs.SERVER}/auth/`;

class AuthService {
    login(address , publicKey,nonce,signature) {
        return axios
            .post(API_URL_AUTH + "login", {
                address,
                nonce,publicKey,signature
                
            })
            .then((response) => {
                if (response.data.address !== address) this.logout();
                if (response.data.access_token) {

                    localStorage.setItem(
                        Envs.TOKEN_NAME,
                        JSON.stringify(response.data.access_token)
                    );
                }
                return { sucess: true, data: response.data };
            }).catch((e) => {
                return { sucess: false, data: { message: e.message } }
            })
            ;

    }

    logout() {
        //todo
    }
}

export default new AuthService();
